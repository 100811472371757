.view-card {
    position: absolute;
    right: 3%;
    top:75%;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    padding: 6px 18px;

}
.view-card:hover {
  cursor: pointer;
}